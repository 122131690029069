import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ProjectPostTemplate = ({ data, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const stack = post.frontmatter.stack
  const image = getImage(
    post.frontmatter.featuredImage.childImageSharp.gatsbyImageData
  )
  const imageAlt = post.frontmatter.featuredImageAlt
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      <article
        itemScope
        itemType="http://schema.org/Article"
        className="flex flex-col items-center"
      >
        <header>
          <h1
            itemProp="headline"
            className="text-black font-bold text-4xl text-center py-16 "
          >
            {post.frontmatter.title}
          </h1>
          <GatsbyImage image={image} alt={imageAlt} />
          <h3 className="text-white font-medium">Stack: {stack}</h3>
        </header>
        <div className="mdx py-8 w-full md:w-4/6">
          <MDXRenderer itemProp="articleBody">{post.body}</MDXRenderer>
        </div>
      </article>
      {/* nav for next/prev post */}
      <nav className="pb-8 z-40">
        <ul className="flex flex-wrap justify-between list-none">
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                <p className="text-lg text-black font-medium highlight">
                  ← {previous.frontmatter.title}
                </p>
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                <p className="text-lg text-black font-medium highlight">
                  {next.frontmatter.title} →
                </p>
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default ProjectPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        stack
        description
        featuredImageAlt
        githubLink
        demoLink
        featuredImage {
          id
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
